<template>
    <div class="p-0">
        <div class="list-clients container p-0">
            <div class="container empty-notification" v-if="! isLoadData && (clients && clients.length < 1)">
                <img src="~@/assets/images/empty_img.svg" alt="">
                <div class="empty-notification__text">
                    <p>Ваша список клієнтів поки порожній. Створіть нового або оформіть страховку або змініть параметри
                        <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> клієнтів.</p>
                </div>
                <a data-toggle="modal" data-target="#modalSearch" class="header__create">Cтворити
                    кліента</a>
            </div>
            <div class="container-wrapp" v-else-if="! isMobile">
                <div class="header-block">
                    <ul class="columns-block">
                        <li class="column">ПІБ</li>
                        <li class="column">Пошта</li>
                        <li class="column">Телефон</li>
                        <li class="column">Дата Створення</li>
                        <li class="column">Відповідальний</li>
                    </ul>
                </div>
                <div v-if="isLoadData">
                    <row-block-skeleton v-for="index in 4" :key="index"></row-block-skeleton>
                </div>
                <div v-else v-for="item in clients" class="row-block">
                    <ul class="columns-block">
                        <li class="column">
                            <div class="column-block">
                                <span class="item-type">{{ showFio(item) }}</span>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                              <span class="item-type">{{ item.email }}</span>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <span class="item-type">{{ item.phone }}</span>
                            </div>
                        </li>
                        <li class="column">
                          <div class="column-block">
                            <div>
                              <span class="item-type">{{ showDateFormat(item)}}</span>
                            </div>
                            <div class="bottom-part">
                             <span class="icon">
                                <svg class="keep-fill" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_11_76)">
                                    <path d="M0.666748 8C0.666748 8 3.33341 2.66667 8.00008 2.66667C12.6667 2.66667 15.3334 8 15.3334 8C15.3334 8 12.6667 13.3333 8.00008 13.3333C3.33341 13.3333 0.666748 8 0.666748 8Z" stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_11_76">
                                      <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                  </defs>
                                </svg>
                             </span>
                              <a @click="fullClientInfo(item)" href="javascript:void(0);">
                                <span class="text">Переглянути</span>
                              </a>
                            </div>
                          </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                              <div class="column-block">
                                <span class="item-type">{{ item.agent.name }}</span>
                              </div>
                                <div class="bottom-part">
                                    <div v-if="loadMoreOptions[item.id + item.typeId] === true"
                                         class="option-loader spinner-border"
                                         style="height: 14px; width: 14px; margin-bottom: 4px;"
                                         role="status">
                                    </div>
                                    <more-options v-else
                                                  :item-type="item.typeId"
                                                  @full-client-info="fullClientInfo(item)"
                                                  @copy-client="copyClient(item)"
                                                  @delete-client="deleteClient(item)">
                                    </more-options>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else>
                <div class="mobile-columns-block">
                    <div v-if="isLoadData">
                        <row-mobile-block-skeleton v-for="index in 4" :key="index"></row-mobile-block-skeleton>
                    </div>
                    <div v-else>
                        <div v-for="item in clients" class="row item-data">
                            <div class="col-7 half-block">
                                <div class="info-block">
                                    <div class="option">ПІБ</div>
                                    <div class="value">{{ showFio(item) }}</div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Дата Створення</div>
                                    <div class="sub-options">
                                        <div>
                                            <span v-if="item.created_at" class="value">{{ showDateFormat(item) }}</span>
                                            <span v-else class="value">&mdash;</span>
                                        </div>
                                    </div>
                                </div>
                              <div class="info-block">
                                <div class="option">Пошта</div>
                                <div class="value">
                                        <span v-if="item.email" class="item-text">
                                            {{ item.email }}
                                        </span>
                                  <span class="item-number" v-else>&mdash;</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-5 half-block">

                                <div class="info-block">
                                    <div class="option">Телефон</div>
                                    <div class="value">
                                       <span v-if="item.phone" class="item-text">
                                            {{ item.phone }}
                                        </span>
                                      <span class="item-number" v-else>&mdash;</span>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Відповідальний</div>
                                    <div class="value">
                                       <span v-if="item.agent" class="item-text">
                                            {{ item.agent.name }}
                                        </span>
                                      <span class="item-number" v-else>&mdash;</span>
                                    </div>
                                </div>
                                <div style="margin-top: auto;color: #999DA6;" class="info-block">
                                    <div class="info-link">
                                        <svg class="keep-fill" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g clip-path="url(#clip0_11_76)">
                                            <path d="M0.666748 8C0.666748 8 3.33341 2.66667 8.00008 2.66667C12.6667 2.66667 15.3334 8 15.3334 8C15.3334 8 12.6667 13.3333 8.00008 13.3333C3.33341 13.3333 0.666748 8 0.666748 8Z" stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_11_76">
                                              <rect width="16" height="16" fill="white"/>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                        <a @click="fullClientInfo(item)" href="javascript:void(0);">
                                          <span class="text">Переглянути</span>
                                        </a>
                                    </div>
                                    <div>
                                        <div v-if="loadMoreOptions[item.id + item.typeId] === true"
                                             class="option-loader spinner-border"
                                             style="height: 20px; width: 20px; margin-top: 11px;"
                                             role="status">
                                        </div>
                                        <more-options style="padding-top: 5px"
                                                      v-else
                                                      @full-client-info="fullClientInfo(item)"
                                                      @copy-client="copyClient(item)"
                                                      @delete-client="deleteClient(item)"
                                        >
                                        </more-options>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <vue-confirm-dialog></vue-confirm-dialog>
    </div>
</template>


<script>
import Vue from 'vue'

import {MoreOptions, RowBlockSkeleton, RowMobileBlockSkeleton, ClientInfo} from './components';
import {deleteClient, getData} from '../../api/client';

const FileDownload = require('js-file-download');

export default {
    mixins: [ ],
    components: {MoreOptions, RowBlockSkeleton, RowMobileBlockSkeleton, ClientInfo, deleteClient},
    props: {
        isLoadData: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        isMobile: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        clients: {
            type: Array,
            default: () => {
                return [{
                    client : null,
                    id : null,
                    user_id : null,
                    fio : null,
                    fio_en : null,
                    email : null,
                    phone : null,
                    created_at : null,
                    agent : null,
                  }];
            },
        },
    },

    data: function () {
        return {
            loadMoreOptions: {},
        }
    },
    created() {

    },
    computed: {
    },
    methods: {
        deleteClient: async function (client) {
          Vue.$confirm({
            title: 'Увага!',
            message: 'Ви дійсно хочете видалити клієнта?',
            button: {
              yes: 'Так',
              no: 'Скасувати'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                let params = {clients_id:client.id};
                deleteClient(params).then((response) => {
                  if(response.data.status == "success"){
                    return this.$emit('init-clients-list');
                  }
                }).catch((error) => {
                  this.condition.isLoadTable = false;
                });
              }
            }
          })
        },
        fullClientInfo: async function(item) {
          this.$router.push({name: 'client_info', params: {clientId: item.id, clientData: item}})
        },
        copyClient: function(client) {
        },
        showDateFormat: function(item) {
          return moment(item.created_at).format("DD-MM-YYYY");
        },
        showClientInfo() {
            this.$refs.clientInfo.handle(true);
        },
        filterStatusWrap: function() {
            return this.$emit('filter-status-wrap');
        },
        showFio(item){
          if(item.fio){
            return item.fio.trim();
          }else if(item.fio_en)
          return (item.fio) ? item.fio.trim() : item.fio_en;
        }
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
.column-block {
  a {
    .text {
      margin-left: 5px;
      line-height: 26px;
    }
  }
}
.list-clients .row-block{
  height: 100px;
}

.vc-container{
   border-radius: 3px;
}
</style>
