import axios from "axios";

/**
 *
 * @param data
 * @returns {*}
 */
export function saveClient(data) {
    return axios({
        url: `/api/${process.env.VUE_APP_SERVER_MODE}/clients`,
        method: 'POST',
        data: data,
    });
}

export function updateClient(data) {
    return axios({
        url: `/api/${process.env.VUE_APP_SERVER_MODE}/clients/`+data.clients_id,
        method: 'PUT',
        data: data,
    });
}

export function deleteClient(data) {
    return axios({
        url: `/api/${process.env.VUE_APP_SERVER_MODE}/clients/`+data.clients_id,
        method: 'DELETE',
    });
}

export function editClient(data) {
    return axios({
        url: `/api/${process.env.VUE_APP_SERVER_MODE}/clients/`+data['clients_id']+`/edit`,
        method: 'GET',
        data: data,
    });
}


/**
 *
 * @param params
 * @returns {*}
 */
export function getReferralUsers(params) {
    return axios({ url: `/api/${process.env.VUE_APP_SERVER_MODE}/referrals`,
        method: 'POST',
        data: params
    });
}

/**
 *
 * @param params
 * @returns {*}
 */
export function getReferralUserBonus(params) {
    console.log('params', params)
    return axios({ url: `/api/${process.env.VUE_APP_SERVER_MODE}/referrals/bonuses`,
        method: 'POST',
        data: params
    });
}

/**
 *
 * @param params
 * @returns {*}
 */
export function getData(params) {
    return axios({ url: `/api/${process.env.VUE_APP_SERVER_MODE}/referrals`,
        method: 'POST',
        data: params
    });
}

export function downloadExcel(query) {
    return axios.post(`/api/${process.env.VUE_APP_SERVER_MODE}/clients/excel`, query, {
        responseType: 'blob'
    });
}
